import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './Contact.css';


function Contact() {

    const [form, setForm] = useState({ name: '', fono: '',email: '', message: ''});

    const handleChange = (e) => {
        const {name, value} = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('fono', form.fono);
        formData.append('email', form.email);
        formData.append('message', form.message);
    
        fetch('https://ratatuin.com/send_email.php', {
            method: 'POST',
            body: formData,
        })
        .then(response => response.text())
        .then(data => {
            console.log('Success:', data);
            alert('Mensaje enviado con éxito!');
        })
        .catch((error) => {
            console.error('Error:', error);
            alert('Hubo un error al enviar el mensaje, por favor intenta de nuevo.');
        });
    };
    

    return (
        <div className="contact-page">
            <h2>Contáctanos</h2>
                <p>
                    Estamos aquí para ayudarte con tus necesidades de control de plagas. 
                    Puedes contactarnos a través del formulario o directamente por WhatsApp.
                </p>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Nombre:</label>
                    <input type="text" name="name" value={form.name} onChange={handleChange} />
                </div>
                <div>
                    <label>Teléfono:</label>
                    <input type="number" name="fono" value={form.fono} onChange={handleChange} />
                </div>
                <div>
                    <label>Email:</label>
                    <input type="email" name="email" value={form.email} onChange={handleChange} />
                </div>
                <div>
                    <label>Mensaje:</label>
                    <textarea name="message" value={form.message} onChange={handleChange}></textarea>
                </div>
                <button type="submit">Enviar</button>
            </form>
            <div className="whatsapp-contact">
                    <p>O contáctanos directamente por WhatsApp para solicitar tu presupuesto:</p>
                    <div className="whatsapp-numbers">
                    <a href="https://wa.me/56926319051" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
                        +56 926319051
                    </a>
                    <span>o al </span>
                    <a href="https://wa.me/56956591444" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
                        +56 956591444
                    </a>
                    </div>
            </div>
        </div>
    );
}

export default Contact;
