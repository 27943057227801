import React from "react";
import './Services.css';
import service1 from '../assets/images/rata3.jpg';
import service2 from '../assets/images/clean2.jpg';
import service3 from '../assets/images/sanita.jpeg';

const Services = () => {
    return (
        <div className="services-container">
            <div className="services-text">
                <h1>Servicios</h1>
                
                <div className="service">
                    <img src={service1} alt="Servicio 1" className="service-image" />
                    <div className="service-description">
                        <h2>Desratización</h2>
                        <p>Es la instalación de estaciones cebaderas, para el control de roedores en cada instalación de nuestros clientes.</p>
                    </div>
                </div>

                <div className="service">
                    <img src={service2} alt="Servicio 2" className="service-image" />
                    <div className="service-description">
                        <h2>Desinsectación</h2>
                        <p>Es la aplicación de productos para el control de insectos, ya sea arañas, moscas, pulgas, tijeretas, cucarachas, chinche de cama, gorgojos, etc.</p>
                    </div>
                </div>

                <div className="service">
                    <img src={service3} alt="Servicio 3" className="service-image" />
                    <div className="service-description">
                        <h2>Sanitización</h2>
                        <p>Es la aplicación de productos amoniocuaternarios para el control de virus, hongos y bacterias.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;