import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faEnvelope, faBug } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Contact from './components/Contact.js';
import logo from './assets/images/logo-ratatuin2.jpeg';

const App = () => {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            {/*<li><Link to="/"> <img src={logo} alt="Inicio" className="logo" />  </Link></li>*/}
            <li><Link to="/"><FontAwesomeIcon icon={faHome} className="icon"/> <span>Inicio</span></Link>  </li>
            <li><Link to="/about-us"><FontAwesomeIcon icon={faInfoCircle} className="icon"/> <span>Nosotros</span></Link></li>
            <li><Link to="/services"><FontAwesomeIcon icon={faBug} className="icon"/> <span>Servicios</span></Link></li>
            <li><Link to="/contact"><FontAwesomeIcon icon={faEnvelope}className="icon"/><span>Contactenos</span></Link></li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;