import React from "react";
import './AboutUs.css';
import companyImage from '../assets/images/mopas.jpg';

const AboutUs = () => {
    return (
        <div className="about">          
            <h3>Quienes Somos</h3>
            <p>Somos una empresa local dedicada a la prestación del Servicio de Manejo Integrado de Control de Plagas; con personal altamente calificado para otorgar un servicio de calidad y con conocimientos necesarios para orientar al cliente.</p>
    
            <h3>Misión</h3>
            <p>Somos una empresa que se construye a través de la experiencia en el manejo Integrado de Plagas, ofreciendo a nuestros clientes soluciones integrales enfocados a mejorar la eficiencia y calidad de nuestros servicios y a su vez asegurar la inocuidad de la producción de nuestros clientes.
                La satisfacción total del cliente es lo que determina cada decisión, acción y tarea que realizamos. Para lograr este objetivo debemos mejorar continuamente nuestros productos, procesos y servicios. Esta es nuestra meta diaria.
                La firme convicción de que la calidad es una de nuestras principales fortalezas que debemos apuntalar día tras día.</p>
            <h3>Visión</h3>
            <p>A través de la calidad de servicio que ofrecemos, llegar a controlar la problemática de nuestros clientes, superando todos los obstáculos que nos ofrecen de manera permanente las plagas presentes y más frecuentes de nuestro entorno.</p>
    
            <h3>Valores</h3>

            <p>Nuestra política de calidad en el servicio de control de plagas está fundamentada en los siguientes valores:</p>
                <ul>
                    <li>Calidad</li>
                    <li>Seguridad</li>
                    <li>Puntualidad</li>
                    <li>Confianza</li>
                    <li>Presentación</li>
                    <li>Atención al cliente</li>
                </ul>
            <img src={companyImage} alt="Company" />
        </div>
    );
};

export default AboutUs;