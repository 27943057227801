import React from "react";
import './Home.css';
import logo from '../assets/images/logo-ratatuin2.jpeg';

const Home = () => {
    return (
        <div className="home-container">
            <div className="intro-text">
                <h1><img src={logo} alt="Inicio" className="logo" />Control Integrado de Plagas Ratatuin</h1>
                <p>Ofrecemos soluciones efectivas y seguras para el control de plagas en su hogar, centro educacional o negocio. Nuestro equipo de profesionales está listo para ayudarle a mantener un ambiente libre de plagas.</p>
            </div>
        </div>
    );
};

export default Home;